import * as React from "react";
import { Component } from "react";
import { DateHelper, fmtMsg } from "../../../../util/index";
import { SchoolLocale } from "../../../../locales/localeid";
import { StudentDetailsModel } from "../../../../service/admin/studentVerification/index";
export interface StudentDetailProps {
        studentInfo: StudentDetailsModel;
        sectionHeading?: String;
        showCurrentUnit: boolean;
}
export class StudentDetailComponent extends Component<StudentDetailProps> {
        static defaultProps = {
                showCurrentUnit: true
        };
        constructor(props) {
                super(props);
        }
        render() {
                const { studentInfo, sectionHeading, showCurrentUnit } = this.props;
                return (
                        studentInfo && <div className="srv_std_detail">
                                <div className="srv_std_detail__sturegtitle">{sectionHeading}</div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudentName })}: </span>
                                        <span>{studentInfo.name}</span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudentEnName })}: </span>
                                        <span>{studentInfo.englishName}</span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudetnBirthday })}: </span>
                                        <span>{DateHelper.toLocalStringFromUTC(
                                                studentInfo.birthday
                                        )}
                                        </span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudentGender })}: </span>
                                        <span>{studentInfo.gender === true ? fmtMsg({ id: SchoolLocale.StudentRegistrationStudentMale }) : studentInfo.gender === false ? fmtMsg({ id: SchoolLocale.StudentRegistrationStudentFemale }) : ""}</span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudentParentEmail })}: </span>
                                        <span>{studentInfo.parentEmail}</span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudentParentPhone })}: </span>
                                        <span>{studentInfo.parentPhoneNumber}</span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudentRegistrationSchoolLabel })}: </span>
                                        <span>{studentInfo.schoolName}</span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudentRegistrationCampusLabel })}: </span>
                                        <span>{studentInfo.campusName}</span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudentRegistrationClassLabel })}: </span>
                                        <span>{studentInfo.className}</span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: showCurrentUnit ? SchoolLocale.StudentRegistrationCurrentUnitLabel : SchoolLocale.StudentUnit })}: </span>
                                        <span>{studentInfo.unit}</span>
                                </div>
                                <div className="srv_std_detail__student-row">
                                        <span className="bold">{fmtMsg({ id: SchoolLocale.StudentRegistrationCurrentUnitStartDateLabel })}: </span>
                                        <span>{DateHelper.toLocalStringFromUTC(
                                                studentInfo.unitStartDate
                                        )}
                                        </span>
                                </div>
                        </div>
                );
        }
}
