import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { GLUtil, UserService, withRouter } from "gl-commonui";
import { isNull, isUndefined, uniq } from "lodash";
import { Button, Col, notification, Row } from "antd-min";
import {
    lazyInject,
    TYPES,
    fmtMsg,
    ChangeLogType,
    EventType,
    ContextHelper,
    DateHelper,
    EventStatus,
    StudentVerificationCase,
    EventInfo,
    isInternalUserWithoutAM
} from "../../../../util/index";
import { CONSTS, NO_MATCH_SELECTED } from "../../../../util/consts";
import { Loading } from "../../../../components/survey/loading/loading";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { MainTitle } from "@app/components";
import { PathConfig } from "@app/config/pathconfig";
import { IUserService } from "../../../../service/users/index";
import { IStudentRegistrationService } from "@app/service/school/student-registration";
import { IStudentService } from "@app/service/school/student";
import {
    ChangeEventPropsModel,
    ICIMSService,
    LicenseChangePropsModel
} from "@app/service/cims";
import {
    IGetStudentInfoWithSuggestionsRequestQueryParam,
    IStudentVerificationService,
    StudentDetailForSuggestionModel,
    StudentDetailsModel,
    StudentSuggestionsModel,
    VerificationCIMSInfoModel
} from "../../../../service/admin/studentVerification/index";
import { ApproveDenyModal } from "@app/page/cims/component/approve-deny-modal";
import "./studentverification.less";
import { StudentDetailComponent } from "./studentdetails";
import { StudentSuggestionsComponent } from "./studentsuggestions";
import { ISchoolClassService } from "@app/service/class";

interface StudentVerificationPageProps extends RouteComponentProps<any> { }
interface StudentVerificationPageStates {
    studentDetailForSuggestion: StudentDetailForSuggestionModel;
    studentInfo: StudentDetailsModel;
    matchedSuggestion: StudentSuggestionsModel;
    suggestions: StudentSuggestionsModel[];
    isLoaded: boolean;
    selectedPotentialMatchId: string;
    selectedMatch: StudentSuggestionsModel | StudentDetailsModel;
    showApproveDenyModal: boolean;
    fieldData: any;
    cimsEventId: string;
    cimsInfo: VerificationCIMSInfoModel;
    leftSectionStudentInfo: StudentDetailsModel;
    rightSectionStudentInfo: StudentDetailsModel;
    rightSectionHeading: String;
    isNextButtonDisabled: boolean;
    currentVerificationCase?: StudentVerificationCase;
    accessibleClassIds: string[];
}

export class StudentVerificationPage extends Component<
    StudentVerificationPageProps,
    StudentVerificationPageStates
> {
    @lazyInject(TYPES.IStudentVerificationService)
    service: IStudentVerificationService;
    @lazyInject(TYPES.IUserService)
    userService: IUserService;
    @lazyInject(TYPES.IStudentRegistrationService)
    studRegService: IStudentRegistrationService;
    @lazyInject(TYPES.IStudentService)
    studentService: IStudentService;
    @lazyInject(TYPES.ICIMSService)
    cims: ICIMSService;
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService;
    constructor(props) {
        super(props);
        this.state = {
            studentDetailForSuggestion: null,
            studentInfo: null,
            suggestions: [],
            matchedSuggestion: null,
            isLoaded: false,
            selectedPotentialMatchId: null,
            selectedMatch: null,
            showApproveDenyModal: false,
            fieldData: null,
            cimsEventId: null,
            cimsInfo: null,
            leftSectionStudentInfo: null,
            rightSectionStudentInfo: null,
            rightSectionHeading: null,
            isNextButtonDisabled: true,
            currentVerificationCase: null,
            accessibleClassIds: []
        };
        this.onSelectSuggestion = this.onSelectSuggestion.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onApproveModal = this.onApproveModal.bind(this);
        this.onDenyModal = this.onDenyModal.bind(this);
        this.redirectToVerificationScreen = this.redirectToVerificationScreen.bind(this);
    }
    regionId = this.props.match.params.regionId;
    studentId = this.props.match.params.mergedStudentId;
    classId = this.props.match.params.mergedClassId;
    selectedMatch = this.props.match.params.potentialMatchId;
    afterMergeStudentInfoText = fmtMsg({ id: SchoolLocale.StudentRegistrationNavigationAfterMergeStudInfo });
    componentDidMount() {
         // get accessible class in case of external user only and account manager
         if (!isInternalUserWithoutAM()) {
            this.schoolClassService.getAccessibleClassIds().then(ids => {
                this.setState({ accessibleClassIds: ids });
                this.getStudentDetails();
            });
        }
        else{
        this.getStudentDetails();
        }
    }
    getStudentDetails() {
        const params: IGetStudentInfoWithSuggestionsRequestQueryParam = {
            studentId: this.studentId,
            currentClassId: this.classId
        };
        this.service.getStudentDetails(params).then(response => {
            this.setState({
                cimsInfo: response.cimsInfo,
                cimsEventId: response.cimsInfo && response.cimsInfo.status == EventStatus.Approved ? response.cimsInfo.id : null,
                studentInfo: response.studentInfo,
                matchedSuggestion: response.matchedSuggestion,
                studentDetailForSuggestion: response.studentDetailForSuggestion,
                leftSectionStudentInfo: response.studentInfo
            });
            this.getSuggestions();
        });
    }
    getSuggestions() {
        const stdDetailForSuggestion: StudentDetailForSuggestionModel[] = [
            this.state.studentDetailForSuggestion
        ];
        const studentId = this.state.studentInfo.studentId;
        this.studRegService
            .getSuggestions(this.regionId, stdDetailForSuggestion)
            .then(response => {

                let suggestionsToSet: any[] = response.matches[0].suggestions.filter(
                    sug => sug.studentId != studentId
                );

                if (!isInternalUserWithoutAM()) {
                    // filter user role level suggestions based on his/her accessible region, school, campus, class
                    suggestionsToSet = suggestionsToSet.filter(
                        s => this.state.accessibleClassIds.includes(s.classId)
                    );
                }

                let currentCase = StudentVerificationCase.OnlyAdditionalMatches;

                if (this.state.matchedSuggestion) {
                    currentCase = StudentVerificationCase.AllMatches;
                }
                else if (!this.state.matchedSuggestion && suggestionsToSet.length === 0) {
                    currentCase = StudentVerificationCase.NoMatch
                }

                this.setState({
                    currentVerificationCase: currentCase,
                    suggestions: suggestionsToSet
                });

                this.getUserEmailsById(
                    this.state.studentInfo,
                    this.state.suggestions,
                    this.state.matchedSuggestion
                );
            });
    }
    getUserEmailsById(studentInfo, suggestions, matchedSuggestion) {
        let parentIds: any;
        if (suggestions) {
            parentIds = suggestions.reduce(
                (a, o) => (o.parentId ? a.concat(o.parentId) : a),
                []
            );
        }
        if (matchedSuggestion) parentIds.push(matchedSuggestion.parentId);
        if (studentInfo) parentIds.push(studentInfo.parentId);
        parentIds = uniq(parentIds);
        parentIds = parentIds.filter(function (el) {
            return el != null;
        });
        if (parentIds.length > 0) {
            this.userService
                .getUsersByPostWithoutLoader({
                    ids: parentIds
                })
                .then(reponseData => {
                    let updatedSuggestion = suggestions;
                    if (
                        !isUndefined(reponseData.data) &&
                        reponseData.data.length > 0
                    ) {
                        updatedSuggestion = suggestions.map(sug => {
                            if (sug.parentId) {
                                let userDetail = reponseData.data.find(
                                    user => user.id == sug.parentId
                                );
                                if (!isUndefined(userDetail)) {
                                    if (!userDetail.disabled) {
                                        sug.parentEmail = userDetail.email;
                                        sug.parentPhoneNumber = userDetail.phone;
                                    } else {
                                        sug.parentId = null;
                                        sug.parentEmail = null;
                                        sug.parentPhoneNumber = null;
                                    }
                                }
                            }
                            return sug;
                        });

                        let userDetail: any;
                        if (studentInfo && studentInfo.parentId) {
                            userDetail = reponseData.data.find(
                                user => user.id == studentInfo.parentId
                            );
                            if (!isUndefined(userDetail)) {
                                if (!userDetail.disabled) {
                                    studentInfo.parentEmail = userDetail.email;
                                    studentInfo.parentPhoneNumber = userDetail.phone;
                                } else {
                                    studentInfo.parentId = null;
                                    studentInfo.parentEmail = null;
                                    studentInfo.parentPhoneNumber = null;
                                }
                            }
                        }
                        if (matchedSuggestion && matchedSuggestion.parentId) {
                            userDetail = reponseData.data.find(
                                user => user.id == matchedSuggestion.parentId
                            );
                            if (!isUndefined(userDetail)) {
                                if (!userDetail.disabled) {
                                    matchedSuggestion.parentEmail = userDetail.email;
                                    matchedSuggestion.parentPhoneNumber = userDetail.phone;
                                } else {
                                    matchedSuggestion.parentId = null;
                                    matchedSuggestion.parentEmail = null;
                                    matchedSuggestion.parentPhoneNumber = null;
                                }
                            }
                        }
                    }
                    this.setState({
                        studentInfo: studentInfo,
                        matchedSuggestion: matchedSuggestion,
                        suggestions: updatedSuggestion,
                        isLoaded: true
                    });

                    this.checkPotentialIdAndGoToFinal();
                })
                .catch(er => {
                    notification.error({
                        message: fmtMsg({
                            id:
                                SchoolLocale.StudentRegistrationSuggestionGetErrorMsg
                        }),
                        className: "tsc-notif"
                    });
                });
        } else {
            this.setState({
                studentInfo: studentInfo,
                matchedSuggestion: matchedSuggestion,
                suggestions: suggestions,
                isLoaded: true
            });

            this.checkPotentialIdAndGoToFinal();
        }
    }

    checkPotentialIdAndGoToFinal() {
        let selectedId = NO_MATCH_SELECTED;
        if (this.selectedMatch) {
            if (this.selectedMatch !== CONSTS.EmptyGuid) {
                selectedId = this.selectedMatch;
            }
            this.goToFinalStep(selectedId, true);
        }
    }

    goToFinalStep(data: any, isActive: boolean) {
        this.onSelectSuggestion(data, isActive);
        this.onSetMatch();
    }
    onSelectSuggestion(data: any, isActive: boolean) {
        if (isActive) this.setState({ selectedPotentialMatchId: data, isNextButtonDisabled: false });
        else this.setState({ selectedPotentialMatchId: null });
    }

    redirectToVerificationScreen() {
        const potentialMatchId = this.state.selectedPotentialMatchId == NO_MATCH_SELECTED ? CONSTS.EmptyGuid : this.state.selectedPotentialMatchId;
        this.props.history.push({ pathname: GLUtil.pathStringify(PathConfig.MergeThenVerifyStudents, { regionId: this.regionId, mergedClassId: this.classId, mergedStudentId: this.studentId, potentialMatchId: potentialMatchId }) });
    }

    onSetMatch() {
        const { studentInfo, currentVerificationCase } = this.state;
        if (this.state.selectedPotentialMatchId == NO_MATCH_SELECTED) {
            // fill info in rightSectionStudentInfo

            let rightSectionStudentInfo: StudentDetailsModel | any = null;

            if (currentVerificationCase === StudentVerificationCase.AllMatches) {
                rightSectionStudentInfo = { ...this.state.matchedSuggestion };
                rightSectionStudentInfo.unit = this.state.matchedSuggestion.currentUnit;
            }

            this.setState({
                selectedMatch: this.state.studentInfo,
                leftSectionStudentInfo: studentInfo,
                rightSectionStudentInfo: rightSectionStudentInfo,
                rightSectionHeading: currentVerificationCase === StudentVerificationCase.AllMatches ? fmtMsg({ id: SchoolLocale.StudentRegistrationNavigationMatchExtUserText }) : ""
            });
        } else if (this.state.selectedPotentialMatchId != null) {
            const selectedMatch = this.state.suggestions.find(
                sug => sug.studentId == this.state.selectedPotentialMatchId
            );
            // fill info in leftSectionStudentInfo
            const leftSectionStudentInfo: StudentDetailsModel = { ...selectedMatch };
            leftSectionStudentInfo.unit = selectedMatch.currentUnit;
            // fill info in rightSectionStudentInfo
            const rightSectionStudentInfo: StudentDetailsModel = { ...studentInfo }
            rightSectionStudentInfo.studentId = selectedMatch.studentId;

            if ((studentInfo.parentEmail != null && studentInfo.parentEmail != selectedMatch.parentEmail) 
                || (studentInfo.parentPhoneNumber != null && studentInfo.parentPhoneNumber != selectedMatch.parentPhoneNumber) ) {
                    rightSectionStudentInfo.parentEmail = studentInfo.parentEmail;
                    rightSectionStudentInfo.parentPhoneNumber = studentInfo.parentPhoneNumber;
            } else {
                rightSectionStudentInfo.parentEmail = selectedMatch.parentEmail;
                rightSectionStudentInfo.parentEmail = selectedMatch.parentPhoneNumber;
            }
           
            this.setState({
                selectedMatch: leftSectionStudentInfo,
                leftSectionStudentInfo: leftSectionStudentInfo,
                rightSectionStudentInfo: rightSectionStudentInfo,
                rightSectionHeading: this.afterMergeStudentInfoText
            });
        } else if (
            this.state.selectedPotentialMatchId == null &&
            this.state.matchedSuggestion != null
        ) {
            const selectedMatch = Object.assign(
                {},
                this.state.matchedSuggestion
            );
            this.setState({ selectedMatch });
        }
    }
    approveStudentMerge() {
        let merStudentId = null;
        if (
            this.state.selectedPotentialMatchId != null &&
            this.state.selectedPotentialMatchId != NO_MATCH_SELECTED
        ) {
            merStudentId = this.state.selectedMatch.studentId;
        }
        this.studentService
            .approveStudentMerge(this.studentId, this.classId, merStudentId)
            .then(response => {
                notification.success({
                    message: fmtMsg({
                        id: GSAdminLocale.RegionManageStudentsSuccess
                    }),
                    className: "tsc-notif"
                });
                this.goToManageStudents();
            });
    }

    goToManageStudents() {
        this.props.history.push({ pathname: GLUtil.pathStringify(PathConfig.ManageStudents, { regionId: this.regionId }) });
    }

    undoStudentMerge() {
        const params: IGetStudentInfoWithSuggestionsRequestQueryParam = {
            studentId: this.studentId,
            currentClassId: this.classId
        };
        this.studentService.undoStudentMerge(params).then(response => {
            notification.success({
                message: fmtMsg({
                    id: GSAdminLocale.RegionManageStudentsUndoSuccess
                }),
                className: "tsc-notif"
            });
            this.goToManageStudents();
        });
    }
    onApproveModal = event => {
        event.preventDefault();
        this.cims
            .approvePendingChanges(
                ChangeLogType.SchoolClass,
                this.state.studentInfo.schoolId,
                this.state.studentInfo.campusId,
                this.classId,
                [EventInfo.PendingChangesChangeLicense]
            )
            .then(response => {
                notification.success({
                    message: fmtMsg({
                        id: SchoolLocale.CIMSSchoolLogEventStatusAcknowledged
                    }),
                    className: "tsc-notif"
                });
                this.onCloseModal();
                this.setState({ cimsEventId: this.state.cimsInfo.id });
            });
    };

    onDenyModal = data => {
        this.cims
            .denyPendingChanges(
                ChangeLogType.SchoolClass,
                this.state.studentInfo.schoolId,
                this.state.studentInfo.campusId,
                this.classId,
                [EventInfo.PendingChangesChangeLicense],
                data
            )
            .then(response => {
                notification.success({
                    message: fmtMsg({
                        id: SchoolLocale.CIMSSchoolLogEventStatusReverted
                    }),
                    className: "tsc-notif"
                });
                this.goToManageStudents();
            });
    };

    onCloseModal() {
        this.setState({ showApproveDenyModal: false });
    }

    getUserName(userId, users) {
        let user = null;
        if (users != null && users.length > 0) {
            user = users.find(user => user.id == userId);
        }
        return user ? user.name : userId;
    }

    formatLicenseChangeData(changeData, users: string[] = []) {
        return changeData
            .map((log, index) => {
                log["index"] = index;
                log[LicenseChangePropsModel.eventType4Display] = fmtMsg({
                    id: CONSTS.CIMSEventType4Display.get(log.eventType)
                });
                log[ChangeEventPropsModel.createdBy] = log.eventCreatedBy
                    ? this.getUserName(log.eventCreatedBy, users)
                    : "";
                log[
                    ChangeEventPropsModel.createdDate
                ] = DateHelper.formatDateTime2Local(
                    log.eventCreatedDate,
                    true,
                    null,
                    true
                );
                return log;
            })
            .sort((a, b) => -a.createdDate.localeCompare(b.createdDate, "en"));
    }
    showApproveDenyModal() {
        this.cims
            .getChangeFieldData(
                this.state.studentInfo.schoolId,
                this.classId,
                EventInfo.PendingChangesChangeLicense,
                this.state.cimsEventId
            )
            .then(response => {
                const { changeDetail, changeFields } = response;
                const userIds = changeDetail
                    .filter(
                        d =>
                            d.eventCreatedBy != null &&
                            d.eventCreatedBy != undefined
                    )
                    .map(d => d.eventCreatedBy);
                if (userIds) {
                    const uniqueUserIds = uniq(userIds);
                    if (uniqueUserIds.length > 0) {
                        this.userService
                            .getItemsBy({
                                ids: uniqueUserIds
                            })
                            .then(users => {
                                const fieldData = this.formatLicenseChangeData(
                                    changeDetail,
                                    users.data
                                );
                                this.setState({
                                    showApproveDenyModal: true,
                                    fieldData: fieldData
                                });
                            });
                    }
                }
            });
    }
    onCancel() {
        const pathChanges = this.regionId
            ? GLUtil.pathStringify(PathConfig.ManageStudents, {
                regionId: this.regionId
            })
            : null;
        this.props.history.push(pathChanges);
    }
    render() {
        const { history } = this.props;
        const approveDenyModalProps = {
            target: ChangeLogType.SchoolClass,
            changeType: EventInfo.PendingChangesChangeLicense,
            visible: true,
            loading: false,
            subTitle: this.state.leftSectionStudentInfo && this.state.leftSectionStudentInfo.className,
            item: this.state.fieldData,
            onApprove: this.onApproveModal,
            onDeny: this.onDenyModal,
            onCancel: this.onCloseModal,
            showCloseButton: this.state.fieldData && this.state.fieldData.filter(a => a.eventStatus === EventStatus.Pending).length == 0,
            verificationCIMSId: this.state.cimsInfo ? this.state.cimsInfo.id : null,
        };
        const { selectedPotentialMatchId, rightSectionHeading, currentVerificationCase } = this.state;
        const showCurrentUnit = currentVerificationCase === StudentVerificationCase.AllMatches ? false : true;
        let leftSectionHeading = fmtMsg({ id: SchoolLocale.StudentRegistrationNavigationNewStudRegText });
        if(selectedPotentialMatchId != NO_MATCH_SELECTED && selectedPotentialMatchId != null){
            leftSectionHeading = fmtMsg({ id: SchoolLocale.StudentRegistrationNavigationBeforeMergeStudInfo });
        }
        return (
            <div className="srv_container">
                {!this.state.isLoaded && <Loading visible={true} />}
                {this.state.isLoaded && (
                    <div className="srv_container_child">


                        <Row gutter={24}>
                            {this.state.selectedMatch && this.state.cimsInfo && (
                                <div className="srv_cims_div">
                                    <a
                                        href="javascript:void(0)"
                                        style={{ float: "right" }}
                                        onClick={() => this.showApproveDenyModal()}
                                    >
                                        {fmtMsg({
                                            id:
                                                GSAdminLocale.RegionManageStudentsViewCIMS
                                        })}
                                    </a>
                                    <br />
                                </div>)}
                        </Row>

                        <Row gutter={24} className="srv_details_section">
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <StudentDetailComponent sectionHeading={leftSectionHeading}
                                    studentInfo={this.state.leftSectionStudentInfo}
                                ></StudentDetailComponent>
                            </Col>


                            {this.state.selectedMatch && (
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                                    {(currentVerificationCase === StudentVerificationCase.AllMatches || currentVerificationCase === StudentVerificationCase.OnlyAdditionalMatches) && <StudentDetailComponent sectionHeading={rightSectionHeading}
                                        studentInfo={this.state.rightSectionStudentInfo} showCurrentUnit={showCurrentUnit}
                                    ></StudentDetailComponent>}

                                </Col>
                            )}

                            {!this.state.selectedMatch && (
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <div className="srv_sug_div">
                                        <div className="srv_sug_div__title--bold">{fmtMsg({ id: SchoolLocale.StudentRegistrationNavigationOtherMatchesText })}</div>
                                        <div className="srv_sug_section">
                                            {this.state.suggestions.map(suggestion => (
                                                <StudentSuggestionsComponent
                                                    suggestions={suggestion}
                                                    key={
                                                        suggestion.studentId + Math.random() +
                                                        "potentialMatch"
                                                    }
                                                    isClickable={true}
                                                    isDefaultSelected={
                                                        this.state
                                                            .selectedPotentialMatchId ==
                                                        suggestion.studentId
                                                    }
                                                    onSelectSuggestion={
                                                        this.onSelectSuggestion
                                                    }
                                                ></StudentSuggestionsComponent>
                                            ))}
                                            <div
                                                className={
                                                    this.state
                                                        .selectedPotentialMatchId ==
                                                        NO_MATCH_SELECTED
                                                        ? "srv_std_sug_selected"
                                                        : "srv_std_sug"
                                                }
                                                onClick={() =>
                                                    this.onSelectSuggestion(
                                                        NO_MATCH_SELECTED,
                                                        true
                                                    )
                                                }
                                            >
                                                {fmtMsg({
                                                    id:
                                                        GSAdminLocale.RegionManageStudentsNoMatch
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}

                        </Row>

                        <Row gutter={24}>


                            <div className="srv_action">
                                {this.state.selectedMatch && (
                                    <>
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                this.approveStudentMerge()
                                            }
                                        >
                                            {fmtMsg({
                                                id:
                                                    GSAdminLocale.RegionManageStudentsApprove
                                            })}
                                        </Button>
                                        <Button
                                            type="default"
                                            onClick={() => this.undoStudentMerge()}
                                        >
                                            {fmtMsg({
                                                id:
                                                    GSAdminLocale.RegionManageStudentsUndo
                                            })}
                                        </Button>
                                    </>
                                )}
                                {!this.state.selectedMatch && (
                                    <Button
                                        type="primary"
                                        onClick={() => this.redirectToVerificationScreen()}
                                        disabled={this.state.isNextButtonDisabled}
                                    >
                                        {fmtMsg({
                                            id:
                                                SchoolLocale.StudentRegistrationNavigationNextText
                                        })}
                                    </Button>
                                )}
                                <Button
                                    type="default"
                                    onClick={() => this.onCancel()}
                                >
                                    {fmtMsg({
                                        id:
                                            SchoolLocale.StudentRegistrationNavigationCancelText
                                    })}
                                </Button>
                            </div>
                        </Row>

                        {this.state.showApproveDenyModal && (
                            <ApproveDenyModal {...approveDenyModalProps} />
                        )}
                    </div>
                )}
            </div>
        );
    }
}
