import * as React from "react";
import { Component } from "react";
import { GLGlobal } from "gl-commonui";
import { Row, Col } from "antd-min";
import { SchoolLocale } from "../../../../locales/localeid";
import { StudentSuggestionsModel } from "../../../../service/admin/studentVerification/index";
import { DateHelper } from "@app/util";
export interface StudentSuggestionsProps {
    suggestions: StudentSuggestionsModel;
    isClickable: boolean;
    isDefaultSelected: boolean;
    onSelectSuggestion: (any, boolean) => void;
    isSuggestionUnhighlighted?: boolean;
}
export interface StudentSuggestionsPageStates {
    isActive: boolean;
}
export class StudentSuggestionsComponent extends Component<
    StudentSuggestionsProps,
    StudentSuggestionsPageStates
> {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false
        };
    }

    onDivClick(studentId, isActive) {
        this.props.onSelectSuggestion(studentId, isActive);
        this.setState({ isActive });
    }

    render() {
        const { suggestions, isDefaultSelected, isClickable, isSuggestionUnhighlighted } = this.props;
        const fmtMsg = GLGlobal.intl.formatMessage;
        const renderGender = (gender: boolean) => {
            switch (gender) {
                case true:
                    return fmtMsg({
                        id: SchoolLocale.StudentRegistrationStudentMale
                    });
                case false:
                    return fmtMsg({
                        id: SchoolLocale.StudentRegistrationStudentFemale
                    });
                default:
                    return "";
            }
        };
        return (
            <div
                className={
                    isSuggestionUnhighlighted ? "srv_std_sug_normal" : isDefaultSelected ? "srv_std_sug_selected" : "srv_std_sug"
                }
                {...(isClickable
                    ? {
                        onClick: () =>
                            this.onDivClick(
                                suggestions.studentId,
                                !this.state.isActive
                            )
                    }
                    : {})}
            >
                <Row type="flex">
                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                        <span>
                            {suggestions.name} ({suggestions.englishName})
                        </span>
                    </Col>
                    <Col xs={14} sm={14} md={14} lg={14} xl={14} >
                        <span className="srv_std_item_label">
                            {fmtMsg({
                                id: SchoolLocale.StudentRegistrationClassLabel
                            })}
                            :{" "}
                        </span>
                        {/* show info if className is available */}
                        {suggestions.className && <span>
                            {suggestions.schoolName} {">"}{" "}
                            {suggestions.campusName} {">"}{" "}
                            {suggestions.className}
                        </span>}
                    </Col>
                </Row>
                <Row>
                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                        <span>
                            {DateHelper.toLocalStringFromUTC(
                                suggestions.birthday
                            )}{" "}
                            {suggestions.birthday && suggestions.gender !== null && "|" } 
                            {renderGender(suggestions.gender)}
                        </span>
                    </Col>
                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                        <span className="srv_std_item_label">
                            {fmtMsg({ id: SchoolLocale.StudentUnit })}:{" "}
                        </span>
                        <span> {suggestions.currentUnit}</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={10}>
                        <span className="srv_std_item_label">
                            {fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionParentEmailTextLabel })}:{" "}
                        </span>
                        <span> {suggestions.parentEmail} </span>
                    </Col>
                    <Col span={14}>
                        <span className="srv_std_item_label">
                            {fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionParentPhoneLabel })}:{" "}
                        </span>
                        <span> {suggestions.parentPhoneNumber} </span>
                    </Col>
                </Row>
            </div>
        );
    }
}
